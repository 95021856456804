import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "benWood",
  "categories": ["company"],
  "date": "2021-06-16",
  "path": "/blog/the-future-of-hint",
  "summary": "Learn about what is next for Hint.",
  "title": "The Future of Hint",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@benjaminwood"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is an article about Hint, and it's the first of its kind. We commonly write about the technical work we're doing, but never about the work we're doing on the business itself. That changes now, and we are committed to sharing about the journey we're on. Spoiler alert - it's going to be awesome!`}</p>
    <p>{`Before I go any further, I'd be remiss if I did not shout out to Heather Corallo of the `}<a parentName="p" {...{
        "href": "https://www.thecanoeproject.com/"
      }}>{`Canoe Project`}</a>{`. She was instrumental in our transformation and continues to guide us as we work towards our goals. Thank you, Heather; we couldn't have done it without you.`}</p>
    <p>{`In the past six months, we've spent countless hours discussing what brought us to where we are today, and how we'd like to evolve over the next ten years. We realized that what got us this far won't take us to the next level, especially if our goals are ambitious (and they are!). Our core values and purpose haven't materially changed, but we've evolved them in a way that offers more clarity than we've ever had before. We have a clear vision of where we're headed as a company, and I couldn't be more excited to share it with you.`}</p>
    <h2>{`Our evolved values`}</h2>
    <p><strong parentName="p">{`Kindness:`}</strong>{` We treat people with compassion and respect.`}</p>
    <p>{`People come first. Software is only the venue.`}</p>
    <p>{`We believe that all people are equally valuable, no matter who they are, where they come from, or what we have to gain from them. With this in mind, we pay equal respect to all and approach each relationship with compassion, considering the `}<em parentName="p">{`whole`}</em>{` person. It's people, not code, that will be our legacy.`}</p>
    <p>{`Kindness sometimes requires respectful communication of difficult things to our clients or co-workers (kindness != niceness).`}</p>
    <p><strong parentName="p">{`Integrity:`}</strong>{` We do what we know is right, and when we don't know, we'll tell you.`}</p>
    <p>{`We do what we say. We do the right thing. Sometimes the right thing is to say "I don't know" and then commit to figuring it out. Integrity requires us to be honest with our assessments and our abilities.`}</p>
    <p><strong parentName="p">{`Teamwork:`}</strong>{` We do our best work when we work together.`}</p>
    <p>{`Together we accomplish things that would be impossible alone. We invest in each other through pairing & mentorship. Our accomplishments are recognized by each other. When we make mistakes, we learn together.`}</p>
    <p>{`Most importantly, we're building meaningful relationships rooted in trust and safety.`}</p>
    <p><strong parentName="p">{`Improvement:`}</strong>{` Continuous improvement applies to everything that we do and everything that we are.`}</p>
    <p>{`We continuously improve the software systems we touch, but it doesn't stop there. We seek continuous improvement in every aspect of our work and even our personal lives.`}</p>
    <p>{`Giving consideration to the whole person, we believe in supporting our team's personal and professional goals, which are unique to each individual.`}</p>
    <h2>{`Our evolved purpose`}</h2>
    <p><strong parentName="p">{`At Hint, we are on a mission to help all software teams reach their full potential.`}</strong></p>
    <p>{`We want to amplify the impact of the work we do. The best way to do that is to focus our efforts on helping others be the best they can be. "Full potential" looks a little different for every team, and we are sensitive to that. Our goal is not to make carbon copies of ourselves but rather to help teams understand what they are capable of and then help them achieve great things.`}</p>
    <p><strong parentName="p">{`Everything is DevOps.`}</strong>{` Every day we're building towards a development process where everyone wins regardless of where they sit in the software development lifecycle (SDLC). We are reshaping our services and doubling down on things like:`}</p>
    <ol>
      <li parentName="ol">{`Sustainable SDLC Practices and Methodology`}</li>
      <li parentName="ol">{`Developer Tooling Strategy & Engineering`}</li>
      <li parentName="ol">{`Continuous Integration / Pipeline Optimization`}</li>
      <li parentName="ol">{`Continuous Delivery / Infrastructure as Code`}</li>
      <li parentName="ol">{`Seamless Application Dependency Upgrades`}</li>
      <li parentName="ol">{`Application Architecture Review & Refactoring`}</li>
      <li parentName="ol">{`Training, Mentoring, and Pair Programming`}</li>
    </ol>
    <h2>{`#Goals`}</h2>
    <p>{`Within ten years...`}</p>
    <p><strong parentName="p">{`We are considered a genuinely innovative consulting company. We live at the forefront of SDLC evolution.`}</strong>{`  `}<em parentName="p">{`We constantly challenge ourselves to think about how things could be different, better, and less complex. As a result, we're doing groundbreaking work, the kind of work that may affect the industry as a whole, not only our current clients.`}</em></p>
    <p>{`No doubt, this is an audacious goal. It won't come easy, but we're prepared to fight for it. We've developed six short(er)-term goals to help position ourselves for this.`}</p>
    <p><strong parentName="p">{`Within 12 months...`}</strong></p>
    <ul>
      <li parentName="ul">{`We will have a minimum of 2 Hint employees working on every project.`}</li>
      <li parentName="ul">{`We will codify our own dynamic coding standards, process, and practices, and all Hint employees will adopt them.`}</li>
      <li parentName="ul">{`We will define our software tooling philosophy and begin to provide advanced infrastructure and DevOps strategy to our customers as a service.`}</li>
    </ul>
    <p><strong parentName="p">{`Within three years...`}</strong></p>
    <ul>
      <li parentName="ul">{`We will evolve the Hint Brand.`}</li>
      <li parentName="ul">{`We will be considered an employer of choice in the software developer community.`}</li>
      <li parentName="ul">{`Each vertical (e.g., operations, sales, development) inside Hint will consist of at least 50% under-represented groups.`}</li>
    </ul>
    <p>{`We've already made some practical day-to-day changes to set our trajectory for success, but I'll cover those in a future article.`}</p>
    <p>{`We have much work to do, and I, for one, am eager to invest myself in this exciting journey. If what you've read resonates with you, ping me on Twitter (`}<a parentName="p" {...{
        "href": "https://twitter.com/benjaminwood"
      }}>{`@benjaminwood`}</a>{`) to let me know. I'd love to hear from you. Better yet, if you're interested in joining our team, please send us an email at `}<a parentName="p" {...{
        "href": "mailto:join@hint.io"
      }}>{`join@hint.io`}</a>{` or see our `}<a parentName="p" {...{
        "href": "/careers"
      }}>{`careers page`}</a>{` for more information (we're hiring!).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      